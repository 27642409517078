.projects {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: 15%;
  color: var(--color-black);
}
.container-projects {
  padding-top: 2rem;
  padding-bottom: 4rem;
}
.card {
  border-radius: 2rem;
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: scale(103%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.card-body {
  color: black;
  background-color: white;
}

.carousel-indicators li {
  background-color: transparent;
  border: 2px solid var(--color-black);
  border-radius: 50%;
  height: 6px;
  width: 6px;
  margin-right: 5px;
  margin-left: 5px;
}

.carousel-indicators .active {
  background-color: var(--color-black);
}

.carousel-inner img {
  transition: transform 0.3s ease;
}

.carousel-inner img:hover {
  transform: scale(1.1);
}

.projects-title {
  color: var(--color-black-variant);
  font-weight: 600;
  text-decoration: underline;
  margin-top: 10rem;
  margin-bottom: 2rem;
}

/* Small screens (up to 576px) */
@media (max-width: 576px) {
  .projects-title {
    padding-top: 3.5rem;
    margin-bottom: 0;
  }
  .container-projects {
    margin-bottom: 5rem;
  }
  .dropdown-item {
    font-size: 0.8rem;
  }
  .nav a {
    padding: 0.15rem 0.6rem;
  }
}
