.contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--color-primary);
  background-color: var(--color-black);
}

.contact {
  padding-top: 7rem;
  padding-bottom: 12rem;
  width: 75vw;
}

.email-gif-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-gif-overlay img {
  width: 100%;
  max-width: 400px;
  margin-bottom: 1rem;
}

.email-gif-overlay p {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-black);
  font-size: 1.4rem;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 576px) {
  .contact-container {
    padding-top: 4rem;
  }
  .contact {
    padding-top: 4rem;
  }

  .contact-h3 {
    font-size: 1.6rem;
  }

  .contact-p {
    font-size: 0.8rem;
  }
}

/* Small screens (up to 576px) */
@media (max-width: 576px) {
}
.email-gif-overlay p {
  font-size: 0.8rem;
}
