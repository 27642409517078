.nav {
  left: 0;
  right: 1rem;
  position: absolute;
  top: 4rem;
  z-index: 999;
}

.white-nav a:focus,
.white-nav a {
  color: var(--color-primary);
  font-weight: 600;
}
.black-nav a:focus,
.black-nav a {
  color: var(--color-black);
  font-weight: 600;
}

.white-nav a:hover {
  color: var(--color-primary);
  font-weight: 600;
  scale: 120%;
}

.black-nav a:hover {
  color: var(--color-black);
  font-weight: 600;
  scale: 120%;
}

.logo {
  width: 6rem;
  position: absolute;
  top: 2rem;
  left: 1rem;
  z-index: 999;
}
.logo:hover {
  scale: 110%;
}

.dropdown-menu {
  background-color: var(--color-primary);
  border: none;
  border-radius: 0;
}

.dropdown-item {
  color: var(--color-black) !important;
}

.dropdown-item:hover {
  background-color: var(--color-primary);
  scale: 100% !important;
}

.logo {
  background-image: url("../../assets/logo.png");
}

.white-logo {
  background-image: url("../../assets/whiteLogo.png");
}

.active-nav {
  text-decoration: underline;
  text-decoration-color: var(--color-primary-red);
  text-underline-offset: 2px;
}
/* Small screens (up to 576px) */
@media (max-width: 576px) {
  .nav {
    top: 2.3rem;
  }
  .nav-link {
    display: block;
    padding: 0.5rem 0.5rem;
  }

  .logo {
    width: 6rem;
    top: 1.2rem;
  }

  .nav-item {
    font-size: 1.1rem;
  }
  .nav a {
    font-size: 1rem;
  }
  .justify-content-end {
    padding-right: 0.9rem;
  }
}
