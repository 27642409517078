* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-black: #1f1f20;
  --color-black-variant: #454547;
  --color-primary: #f5eee6;
  --color-primary-variant: #f6e5d3;
  --color-dark: #171a22;
  --color-primary-red: #c83c27;
  --color-primary-blue: #474b74;
  --color-primary-green: #387144;
  --color-primary-yellow: #cd9029;
  --color-primary-pink: #c56957;
  --color-primary-beige: #ccbca1;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Montserrat", sans-serif;
  color: #f5eee6;
  color: var(--color-primary);
  line-height: 1.7;
}
.container-portfolio {
  padding-top: 20%;
  padding-bottom: 20%;
}
.container {
  width: 75%;
  width: var(--container-width-lg);
  margin: 0, auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

a {
  transition: all 400ms ease;
  transition: var(--transition);
}
body.popup-open {
  overflow: hidden;
}
.btn,
.btn-light {
  background-color: #f5eee6;
  background-color: var(--color-primary);
  color: #171a22;
  color: var(--color-dark);
  border: 1px solid #f5eee6;
  border: 1px solid var(--color-primary);
  transition: all 400ms ease;
  transition: var(--transition);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.btn:hover,
.btn-light:hover {
  background-color: #f6e5d3;
  background-color: var(--color-primary-variant);
  color: #171a22;
  color: var(--color-dark);
  border-color: transparent;
}

.btn:focus,
.btn-light:focus {
  outline: none;
}
img {
  display: block;
  width: 100%;
  object-fit: cover;
}


.nav {
  left: 0;
  right: 1rem;
  position: absolute;
  top: 4rem;
  z-index: 999;
}

.white-nav a:focus,
.white-nav a {
  color: var(--color-primary);
  font-weight: 600;
}
.black-nav a:focus,
.black-nav a {
  color: var(--color-black);
  font-weight: 600;
}

.white-nav a:hover {
  color: var(--color-primary);
  font-weight: 600;
  scale: 120%;
}

.black-nav a:hover {
  color: var(--color-black);
  font-weight: 600;
  scale: 120%;
}

.logo {
  width: 6rem;
  position: absolute;
  top: 2rem;
  left: 1rem;
  z-index: 999;
}
.logo:hover {
  scale: 110%;
}

.dropdown-menu {
  background-color: var(--color-primary);
  border: none;
  border-radius: 0;
}

.dropdown-item {
  color: var(--color-black) !important;
}

.dropdown-item:hover {
  background-color: var(--color-primary);
  scale: 100% !important;
}

.logo {
  background-image: url(/static/media/logo.ae31fc18.png);
}

.white-logo {
  background-image: url(/static/media/whiteLogo.dd1d3479.png);
}

.active-nav {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-primary-red);
          text-decoration-color: var(--color-primary-red);
  text-underline-offset: 2px;
}
/* Small screens (up to 576px) */
@media (max-width: 576px) {
  .nav {
    top: 2.3rem;
  }
  .nav-link {
    display: block;
    padding: 0.5rem 0.5rem;
  }

  .logo {
    width: 6rem;
    top: 1.2rem;
  }

  .nav-item {
    font-size: 1.1rem;
  }
  .nav a {
    font-size: 1rem;
  }
  .justify-content-end {
    padding-right: 0.9rem;
  }
}

footer {
  position: fixed;
  padding-top: 1rem;
  padding-bottom: 1rem;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.white-footer {
  color: var(--color-primary);
  background-color: var(--color-black);
}

.black-footer {
  color: var(--color-black);
  background-color: white;
}

@media (max-width: 576px) {
  .footer {
    font-size: 0.8rem;
  }
}

.loading-spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@font-face {
  font-family: "Tusker Grotesk";
  src: url(/static/media/TuskerGrotesk-2500Medium.31a2deae.ttf);
}

.bg-color {
  background-color: var(--color-black);
  padding-left: 3rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.bg-image {
  background-image: url(/static/media/foto.7652a08e.webp);
  background-size: cover;
  background-position: right top;
}
.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black);
  color: var(--color-primary);
}
.about {
  padding-left: 5%;
}
.about-head {
  font-family: "Tusker Grotesk", sans-serif;
  z-index: 999;
  padding-top: 25%;
  line-height: 10rem;
  font-size: 10rem;
}
.fa-linkedin,
.fa-github {
  width: 2.4rem;
  font-size: 1.6rem;
}

.i-experience {
  font-size: 2rem;
  padding-bottom: 1rem;
}

a {
  text-decoration: none;
  color: var(--color-black);
}

.about-p {
  padding-right: 30%;
  padding-top: 5%;
  padding-bottom: 3%;
}
.link-socialMedia i:hover {
  font-size: 2em;
}
.experience {
  padding: 4rem 2rem;
  background-color: var(--color-primary);
  color: var(--color-black);
}
.experience-link:hover {
  text-decoration: none;
  color: var(--color-primary-yellow);
}
.card {
  border: none;
  color: var(--color-black);
  height: 100%;
}

.title {
  font-weight: 600;
  font-size: 1rem;
  padding-top: 0.8rem;
  padding-bottom: 0.4rem;
}

.experience-container {
  padding-bottom: 4rem;
}

/* Small screens (up to 576px) */
@media (max-width: 576px) {
  .flex-md-row {
    flex-direction: column;
  }
  .changing-text {
    font-size: 10rem;
    line-height: 1;
  }
  .home-h4 {
    font-size: 1.2rem;
    padding-bottom: 2rem;
  }
  .about-head {
    margin-top: 3rem;
    font-size: 7.5rem;
    line-height: 1.1;
    text-align: center;
    padding-right: 1.5rem;
    padding-top: 8rem;
  }
  .p {
    font-size: 1.2rem;
  }
  .about-p {
    text-align: center;
    padding: 0;
  }
  .about-btn {
    display: flex;
    justify-content: center;
  }
  .about-link {
    display: flex;
    justify-content: center;
  }

  .experience-title {
    font-size: 1.8rem;
  }

  .i-experience {
    font-size: 1.6rem;
  }
}

/* Large screens (768px and up) */
@media (min-width: 768px) {
  .home-h4 {
    font-size: 2rem;
  }
}

.contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--color-primary);
  background-color: var(--color-black);
}

.contact {
  padding-top: 7rem;
  padding-bottom: 12rem;
  width: 75vw;
}

.email-gif-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-gif-overlay img {
  width: 100%;
  max-width: 400px;
  margin-bottom: 1rem;
}

.email-gif-overlay p {
  position: absolute;
  top: 35%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: var(--color-black);
  font-size: 1.4rem;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 576px) {
  .contact-container {
    padding-top: 4rem;
  }
  .contact {
    padding-top: 4rem;
  }

  .contact-h3 {
    font-size: 1.6rem;
  }

  .contact-p {
    font-size: 0.8rem;
  }
}

/* Small screens (up to 576px) */
@media (max-width: 576px) {
}
.email-gif-overlay p {
  font-size: 0.8rem;
}

.home-container {
  background-image: url(/static/media/backgroundImage.0536775c.png);
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 5rem;
}

.head {
  color: var(--color-primary);
  z-index: 999;
  padding-top: 26vh;
  padding-left: 3rem;
  padding-right: 1rem;
}

@font-face {
  font-family: "Tusker Grotesk";
  src: url(/static/media/TuskerGrotesk-2500Medium.31a2deae.ttf);
}

.changing-text {
  font-family: "Tusker Grotesk", sans-serif;
  font-size: 14rem;
  line-height: 13.5rem;
}

.and {
  font-family: "Tusker Grotesk", sans-serif;
  font-size: 5rem;
  color: #000;
}

.home-h4 {
  font-size: 2rem;
  line-height: 1.3;
}

.home-span {
  position: relative;
  display: inline-block;
  font-weight: bold;
}

.home-span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: #333;
}

/* Small screens (up to 576px) */
@media (max-width: 576px) {
  .flex-md-row {
    flex-direction: column;
  }
  .head {
    padding-left: 3rem;
    padding-top: 12rem;
  }
  .changing-text {
    font-size: 10rem;
    line-height: 1;
  }
  .home-h4 {
    font-size: 1.2rem;
    padding-bottom: 2rem;
    padding-top: 1rem;
  }
}

/* Medium screens (576px - 768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .home-h4 {
    font-size: 1.5rem;
  }
}

@media (max-width: 900px) {
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.projects {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: 15%;
  color: var(--color-black);
}
.container-projects {
  padding-top: 2rem;
  padding-bottom: 4rem;
}
.card {
  border-radius: 2rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.card:hover {
  -webkit-transform: scale(103%);
          transform: scale(103%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.card-body {
  color: black;
  background-color: white;
}

.carousel-indicators li {
  background-color: transparent;
  border: 2px solid var(--color-black);
  border-radius: 50%;
  height: 6px;
  width: 6px;
  margin-right: 5px;
  margin-left: 5px;
}

.carousel-indicators .active {
  background-color: var(--color-black);
}

.carousel-inner img {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.carousel-inner img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.projects-title {
  color: var(--color-black-variant);
  font-weight: 600;
  text-decoration: underline;
  margin-top: 10rem;
  margin-bottom: 2rem;
}

/* Small screens (up to 576px) */
@media (max-width: 576px) {
  .projects-title {
    padding-top: 3.5rem;
    margin-bottom: 0;
  }
  .container-projects {
    margin-bottom: 5rem;
  }
  .dropdown-item {
    font-size: 0.8rem;
  }
  .nav a {
    padding: 0.15rem 0.6rem;
  }
}

.projects {
  margin-top: 15%;
  margin-bottom: 15%;
}

.card {
  border: none;
  background-color: white;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.7), 0 15px 12px rgba(0, 0, 0, 0.22);
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}
.card-title {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-primary);
}
.card-info {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--color-primary);
}
.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card:hover img {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}
.card-body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s;
}
.card:hover .card-body {
  opacity: 1;
}
.pt-2 {
  color: var(--color-black-variant);
}

.popup-close {
  background-color: white;
  font-size: 1.5rem;
  font-weight: 600;
  font-size: larger;
  color: var(--color-black);
  position: fixed;
  z-index: 1;
  left: 4rem;
  top: 4rem;
}
.carousel-control-prev,
.carousel-control-next {
  background-color: var(--color-black-variant);
  opacity: 0.5;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  padding: 0.5rem;
  margin-top: 25%;
}

.carousel-item {
  scale: 0.9;
}

.tech {
  display: inline-block;
  margin-top: 0.8rem;
  margin-right: 1rem;
  margin-bottom: 0.4rem;
  border: 1px solid var(--color-primary-yellow);
  border-radius: 1.2rem;
  padding: 0.3rem 0.4rem;
  color: var(--color-primary-yellow);
  font-size: 0.8rem;
}
.tech-title {
  color: var(--color-black-variant);
  font-weight: 600;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-inner {
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  padding: 4rem;
  border-radius: 1rem;
}

.custom-btn-class {
  color: black;
}

/* Small screens (up to 576px) */
@media (max-width: 576px) {
  .carousel-control-prev,
  .carousel-control-next {
    height: 1.2rem;
    width: 1.2rem;
    padding: 0.2rem;
  }
  .popup-close {
    left: 3rem;
    top: 4rem;
  }

  .popup-inner {
    padding: 2.5rem;
  }
}

@media (min-width: 992px) {
  .popup-close {
    left: 6rem;
    top: 6rem;
  }
}

.design-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgb(28, 27, 26);
}

.arc-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

