footer {
  position: fixed;
  padding-top: 1rem;
  padding-bottom: 1rem;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.white-footer {
  color: var(--color-primary);
  background-color: var(--color-black);
}

.black-footer {
  color: var(--color-black);
  background-color: white;
}

@media (max-width: 576px) {
  .footer {
    font-size: 0.8rem;
  }
}
