.home-container {
  background-image: url("../../assets/backgroundImage.png");
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 5rem;
}

.head {
  color: var(--color-primary);
  z-index: 999;
  padding-top: 26vh;
  padding-left: 3rem;
  padding-right: 1rem;
}

@font-face {
  font-family: "Tusker Grotesk";
  src: url("../../TuskerGrotesk-2500Medium.ttf");
}

.changing-text {
  font-family: "Tusker Grotesk", sans-serif;
  font-size: 14rem;
  line-height: 13.5rem;
}

.and {
  font-family: "Tusker Grotesk", sans-serif;
  font-size: 5rem;
  color: #000;
}

.home-h4 {
  font-size: 2rem;
  line-height: 1.3;
}

.home-span {
  position: relative;
  display: inline-block;
  font-weight: bold;
}

.home-span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: #333;
}

/* Small screens (up to 576px) */
@media (max-width: 576px) {
  .flex-md-row {
    flex-direction: column;
  }
  .head {
    padding-left: 3rem;
    padding-top: 12rem;
  }
  .changing-text {
    font-size: 10rem;
    line-height: 1;
  }
  .home-h4 {
    font-size: 1.2rem;
    padding-bottom: 2rem;
    padding-top: 1rem;
  }
}

/* Medium screens (576px - 768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .home-h4 {
    font-size: 1.5rem;
  }
}

@media (max-width: 900px) {
  .col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
