@font-face {
  font-family: "Tusker Grotesk";
  src: url("../../TuskerGrotesk-2500Medium.ttf");
}

.bg-color {
  background-color: var(--color-black);
  padding-left: 3rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.bg-image {
  background-image: url("../../assets/foto.webp");
  background-size: cover;
  background-position: right top;
}
.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black);
  color: var(--color-primary);
}
.about {
  padding-left: 5%;
}
.about-head {
  font-family: "Tusker Grotesk", sans-serif;
  z-index: 999;
  padding-top: 25%;
  line-height: 10rem;
  font-size: 10rem;
}
.fa-linkedin,
.fa-github {
  width: 2.4rem;
  font-size: 1.6rem;
}

.i-experience {
  font-size: 2rem;
  padding-bottom: 1rem;
}

a {
  text-decoration: none;
  color: var(--color-black);
}

.about-p {
  padding-right: 30%;
  padding-top: 5%;
  padding-bottom: 3%;
}
.link-socialMedia i:hover {
  font-size: 2em;
}
.experience {
  padding: 4rem 2rem;
  background-color: var(--color-primary);
  color: var(--color-black);
}
.experience-link:hover {
  text-decoration: none;
  color: var(--color-primary-yellow);
}
.card {
  border: none;
  color: var(--color-black);
  height: 100%;
}

.title {
  font-weight: 600;
  font-size: 1rem;
  padding-top: 0.8rem;
  padding-bottom: 0.4rem;
}

.experience-container {
  padding-bottom: 4rem;
}

/* Small screens (up to 576px) */
@media (max-width: 576px) {
  .flex-md-row {
    flex-direction: column;
  }
  .changing-text {
    font-size: 10rem;
    line-height: 1;
  }
  .home-h4 {
    font-size: 1.2rem;
    padding-bottom: 2rem;
  }
  .about-head {
    margin-top: 3rem;
    font-size: 7.5rem;
    line-height: 1.1;
    text-align: center;
    padding-right: 1.5rem;
    padding-top: 8rem;
  }
  .p {
    font-size: 1.2rem;
  }
  .about-p {
    text-align: center;
    padding: 0;
  }
  .about-btn {
    display: flex;
    justify-content: center;
  }
  .about-link {
    display: flex;
    justify-content: center;
  }

  .experience-title {
    font-size: 1.8rem;
  }

  .i-experience {
    font-size: 1.6rem;
  }
}

/* Large screens (768px and up) */
@media (min-width: 768px) {
  .home-h4 {
    font-size: 2rem;
  }
}
