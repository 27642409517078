.projects {
  margin-top: 15%;
  margin-bottom: 15%;
}

.card {
  border: none;
  background-color: white;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.7), 0 15px 12px rgba(0, 0, 0, 0.22);
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}
.card-title {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-primary);
}
.card-info {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--color-primary);
}
.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card:hover img {
  transform: scale(1.4);
}
.card-body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s;
}
.card:hover .card-body {
  opacity: 1;
}
.pt-2 {
  color: var(--color-black-variant);
}

.popup-close {
  background-color: white;
  font-size: 1.5rem;
  font-weight: 600;
  font-size: larger;
  color: var(--color-black);
  position: fixed;
  z-index: 1;
  left: 4rem;
  top: 4rem;
}
.carousel-control-prev,
.carousel-control-next {
  background-color: var(--color-black-variant);
  opacity: 0.5;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  padding: 0.5rem;
  margin-top: 25%;
}

.carousel-item {
  scale: 0.9;
}

.tech {
  display: inline-block;
  margin-top: 0.8rem;
  margin-right: 1rem;
  margin-bottom: 0.4rem;
  border: 1px solid var(--color-primary-yellow);
  border-radius: 1.2rem;
  padding: 0.3rem 0.4rem;
  color: var(--color-primary-yellow);
  font-size: 0.8rem;
}
.tech-title {
  color: var(--color-black-variant);
  font-weight: 600;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-inner {
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  padding: 4rem;
  border-radius: 1rem;
}

.custom-btn-class {
  color: black;
}

/* Small screens (up to 576px) */
@media (max-width: 576px) {
  .carousel-control-prev,
  .carousel-control-next {
    height: 1.2rem;
    width: 1.2rem;
    padding: 0.2rem;
  }
  .popup-close {
    left: 3rem;
    top: 4rem;
  }

  .popup-inner {
    padding: 2.5rem;
  }
}

@media (min-width: 992px) {
  .popup-close {
    left: 6rem;
    top: 6rem;
  }
}
