* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-black: #1f1f20;
  --color-black-variant: #454547;
  --color-primary: #f5eee6;
  --color-primary-variant: #f6e5d3;
  --color-dark: #171a22;
  --color-primary-red: #c83c27;
  --color-primary-blue: #474b74;
  --color-primary-green: #387144;
  --color-primary-yellow: #cd9029;
  --color-primary-pink: #c56957;
  --color-primary-beige: #ccbca1;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Montserrat", sans-serif;
  color: var(--color-primary);
  line-height: 1.7;
}
.container-portfolio {
  padding-top: 20%;
  padding-bottom: 20%;
}
.container {
  width: var(--container-width-lg);
  margin: 0, auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

a {
  transition: var(--transition);
}
body.popup-open {
  overflow: hidden;
}
.btn,
.btn-light {
  background-color: var(--color-primary);
  color: var(--color-dark);
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.btn:hover,
.btn-light:hover {
  background-color: var(--color-primary-variant);
  color: var(--color-dark);
  border-color: transparent;
}

.btn:focus,
.btn-light:focus {
  outline: none;
}
img {
  display: block;
  width: 100%;
  object-fit: cover;
}
